<!-- 问题管理 -->
<template>
  <div class="trouble_management">
    <div class="container">
      <!--  <el-card>
        <el-form label-width="120px" :model="queryInfo">
          <el-form-item label="工具类型:">
            <el-radio-group v-model="queryInfo.evaluationType" @change="search">
              <el-radio-button label="">全部 </el-radio-button>
              <el-radio-button label="1">企业数智化转型诊断评估 </el-radio-button>
              <el-radio-button label="2">企业数智化转型政策申报 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="问题类型:">
            <el-radio-group v-model="queryInfo.questionsType" @change="search">
              <el-radio-button label="">全部 </el-radio-button>
              <el-radio-button label="1">企业数智化转型诊断评估 </el-radio-button>
              <el-radio-button label="2">企业数智化转型政策申报 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="行业类别:">
            <el-radio-group v-model="queryInfo.evaluationType" @change="search">
              <el-radio-button label="">全部 </el-radio-button>
              <el-radio-button label="1">企业数智化转型诊断评估 </el-radio-button>
              <el-radio-button label="2">企业数智化转型政策申报 </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="申报地区:">
            <city-select-id
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
            />
          </el-form-item>
        </el-form>
      </el-card> -->
      <el-card>
        <div class="content_top">
          <!-- 左边按钮 -->
          <div class="btns">
            <el-button type="primary" @click="addPolicy">新增</el-button>
            <el-button plain @click="deleteProblem">批量删除</el-button>
          </div>
          <!-- 右边搜索 -->
          <div class="search_right">
            <el-input
              placeholder="请输入标题、说明进行搜索"
              v-model="queryInfo.queryConditions"
              class="input-with-select"
              style="width: 500px"
            >
              <el-button slot="append" @click="changeSearchangeSearch" type="primary">搜索</el-button>
            </el-input>
          </div>
        </div>
        <el-table :data="tableData" style="width: 100%" @selection-change="selectionChanged">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="编号" min-width="80" align="center">
            <template v-slot="{ row }">
              <span>G00{{ row.id }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="questionsType" label="问题类型" min-width="200" align="center">
          </el-table-column> -->
          <el-table-column
            prop="caption"
            label="问题标题"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="descriptionOfOptions"
            label="问题说明"
            min-width="120"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            prop="evaluationType"
            label="评估类型"
            min-width="120"
            align="center"
          ></el-table-column> -->
          <!-- <el-table-column
            prop="industryCategory"
            label="行业类型"
            min-width="120"
            align="center"
          ></el-table-column> -->

          <el-table-column label="关联工具" min-width="120" align="center">
            <template v-slot="{ row }">
              <el-tooltip effect="dark" :visible-arrow="false" placement="bottom">
                <div slot="content">
                  <div class="relate_tool_tip">{{ row.questionnaireNames }}</div>
                </div>
                <div class="relate_tools">
                  <span class="color1">{{ row.questionnaireNames }}</span>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateName"
            label="操作人"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column prop="updateTime" label="操作时间" min-width="120" align="center">
            <template v-slot="{ row }">
              <div>{{ row.updateTime | date }}</div>
              <div>{{ row.updateTime | time }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="操作" min-width="100" align="center">
            <template v-slot="{ row }">
              <div class="btn_list">
                <span class="color1" @click="editPolicy(row.id)">编辑</span>
                <div @click="setFreeze(row)">
                  <span class="color2" v-if="row.isFreeze">激活</span>
                  <span class="color2" v-else>冻结</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { problemList, SetProblemFreeze, deleteProblem } from '@/api/problem.js'
//import CitySelectId from '@/components/CitySelectId.vue'

const defaultQueryInfo = Object.freeze({
  isGroup: false, //是否在问题组中展示
  city: '',
  country: '',
  evaluationType: '', //评估类型(1:企业数智化转型诊断评估,2:企业数智化转型政策申报)
  industryCategory: [], //行业类别集合
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  province: '',
  queryConditions: '',
  questionsType: '' //问题类型：企业基本信息、数智化转型战略、数智化转型基础、数智化转型应用、数智化应用成效、企业产品与服务、其它、战略规划、组织规划
})
export default {
  name: 'troubleManagement',
  components: {},
  data() {
    return {
      isShowBox: false, // 显示关联工具box
      questionnaireNames: '', // 关联工具
      queryInfo: { ...defaultQueryInfo }, //查询参数
      tableData: [], //表格数据
      total: 0, // 总条数
      rows: [] //选中的行
    }
  },
  computed: {},
  created() {
    // this.search()
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //查询一览
    async search() {
      const { data: res } = await problemList(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.getQuestionnaireNames()
      } else {
        this.$message.error('获取问题列表失败！')
      }
    },
    // 获取关联工具文字
    getQuestionnaireNames() {
      this.tableData.forEach((item) => {
        if (item.questionnaireNames) {
          if (item.questionnaireNames.length > 0) {
            let arr = []
            item.questionnaireNames.map((el) => {
              arr.push('#' + el)
            })
            item.questionnaireNames = arr.join('、')
          }
        }
      })
    },
    //批量删除问题
    deleteProblem() {
      let questionsId = []
      this.rows.forEach((item) => {
        questionsId.push(item.id)
      })
      console.log(questionsId)
      this.$confirm('请确认是否删除 ?', '提示')
        .then(async () => {
          const { data: res } = await deleteProblem(questionsId)
          if (res.resultCode === 200) {
            this.$message.success('删除成功!')
            this.search()
          } else {
            this.$message.error('删除失败!')
          }
        })
        .catch(() => {})
    },
    //选中行
    selectionChanged(rows) {
      this.rows = rows
    },
    //冻结、解冻
    async setFreeze(row) {
      const { data: res } = await SetProblemFreeze({
        id: row.id,
        isFreeze: !row.isFreeze
      })
      if (res.resultCode === 200) {
        this.$message.success(`${!row.isFreeze ? '冻结成功!' : '解冻成功!'}`)
        this.search()
      } else {
        this.$message.error(`${!row.isFreeze ? '解冻失败!' : '冻结失败!'}`)
      }
    },
    //新增问题
    addPolicy() {
      this.$router.push({
        name: 'addTrouble',
        query: {
          echo: 0
        }
      })
    },
    //编辑问题
    editPolicy(id) {
      this.$router.push({
        name: 'addTrouble',
        query: {
          id,
          echo: 1,
          pageNum: this.queryInfo.pageNum
        }
      })
    },
    //返回省
    searchProvince(val) {
      console.log(val)
      this.queryInfo.province = val
      this.search()
    },
    //返回城市
    async searchCity(val, id) {
      if (id == 0) {
        this.selectInstitution(0)
      } else if (id != null) {
        this.selectInstitution(id)
      }
      this.queryInfo.city = val
      this.search()
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      localStorage.setItem('paginationCurrentPage', newCurrent)
      this.search()
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.content_top {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 10px;
  .search_right {
    width: 560px;
    ::v-deep .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 4px;
      background-color: #448aff;
    }
  }
}
.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 10px;
    cursor: pointer;
  }
}
.container {
  height: 80vh;
  overflow: scroll;
  .relate_tool_tip {
    width: 200px;
  }
  .relate_tools {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
  }
}
.pagination {
  width: 100%;
  text-align: center;
  margin: 10px 0 20px 0;
}
.color1 {
  color: #4e93fb;
}
.color2 {
  color: #fd5469;
}
</style>
