var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trouble_management"},[_c('div',{staticClass:"container"},[_c('el-card',[_c('div',{staticClass:"content_top"},[_c('div',{staticClass:"btns"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addPolicy}},[_vm._v("新增")]),_c('el-button',{attrs:{"plain":""},on:{"click":_vm.deleteProblem}},[_vm._v("批量删除")])],1),_c('div',{staticClass:"search_right"},[_c('el-input',{staticClass:"input-with-select",staticStyle:{"width":"500px"},attrs:{"placeholder":"请输入标题、说明进行搜索"},model:{value:(_vm.queryInfo.queryConditions),callback:function ($$v) {_vm.$set(_vm.queryInfo, "queryConditions", $$v)},expression:"queryInfo.queryConditions"}},[_c('el-button',{attrs:{"slot":"append","type":"primary"},on:{"click":_vm.changeSearchangeSearch},slot:"append"},[_vm._v("搜索")])],1)],1)]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData},on:{"selection-change":_vm.selectionChanged}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"label":"编号","min-width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v("G00"+_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"prop":"caption","label":"问题标题","min-width":"120","align":"center"}}),_c('el-table-column',{attrs:{"prop":"descriptionOfOptions","label":"问题说明","min-width":"120","align":"center"}}),_c('el-table-column',{attrs:{"label":"关联工具","min-width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{attrs:{"effect":"dark","visible-arrow":false,"placement":"bottom"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"relate_tool_tip"},[_vm._v(_vm._s(row.questionnaireNames))])]),_c('div',{staticClass:"relate_tools"},[_c('span',{staticClass:"color1"},[_vm._v(_vm._s(row.questionnaireNames))])])])]}}])}),_c('el-table-column',{attrs:{"prop":"updateName","label":"操作人","min-width":"120","align":"center"}}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"操作时间","min-width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm._f("date")(row.updateTime)))]),_c('div',[_vm._v(_vm._s(_vm._f("time")(row.updateTime)))])]}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"操作","min-width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"btn_list"},[_c('span',{staticClass:"color1",on:{"click":function($event){return _vm.editPolicy(row.id)}}},[_vm._v("编辑")]),_c('div',{on:{"click":function($event){return _vm.setFreeze(row)}}},[(row.isFreeze)?_c('span',{staticClass:"color2"},[_vm._v("激活")]):_c('span',{staticClass:"color2"},[_vm._v("冻结")])])])]}}])})],1)],1)],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-sizes":[10, 30, 50],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }